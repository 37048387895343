<template>
  <div>
    <!-- 搜索框 -->
    <div class="sousuo">
      <span class="inputSearch" id="searchInput">
        <el-input
          v-model="keywords"
          class="form-control"
          placeholder="请输入订单编号、课称名称、海师姓名、客户姓名、客户手机号"
        >
          <template slot="append">
            <div @click="getFinanceListMeth('search')">
              <i class="el-icon-search"></i>
              搜索
            </div>
          </template>
        </el-input>
      </span>
      <el-select
        v-model="bmValue"
        placeholder="部门名称"
        @change="getFinanceListMeth()"
      >
        <el-option
          v-for="item in bmOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="htValue"
        placeholder="合同类型"
        @change="getFinanceListMeth()"
      >
        <el-option
          v-for="item in htOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="reset" @click="reset">重置</div>
    </div>
    <!-- 表格 -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      id="daochu"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="course_name" label="课程名称" width="120">
      </el-table-column>
      <el-table-column prop="class_time" label="上课时间" width="110">
      </el-table-column>
      <el-table-column prop="user_name" label="学生姓名" width="120">
      </el-table-column>
      <el-table-column prop="department" label="部门名称" width="120">
      </el-table-column>
      <el-table-column prop="contract_type" label="合同类型" width="120">
      </el-table-column>
      <el-table-column prop="gwname" label="对接老师" width="120">
      </el-table-column>
      <el-table-column prop="course_duration" label="课程时长" width="120">
      </el-table-column>
      <el-table-column prop="teacher_name" label="海师姓名" width="120">
      </el-table-column>
      <el-table-column
        prop="service_category_name"
        label="服务类型"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="service_content" label="服务内容" width="120">
      </el-table-column>
      <el-table-column prop="course_price" label="课程单价" width="120">
        <template slot-scope="scope">
          {{ scope.row.currency == 1 ? "￥" : "$" }}{{ scope.row.course_price }}
        </template>
      </el-table-column>
      <el-table-column prop="confirm_course_price" label="确认单价" width="120">
        <template slot-scope="scope">
          {{ scope.row.currency == 1 ? "￥" : "$"
          }}{{ scope.row.confirm_course_price }}
        </template>
      </el-table-column>
      <!-- 课程状态 -->
      <el-table-column
        prop="coursestate"
        fixed="right"
        label="课程状态"
        width="90"
      >
        <template slot-scope="scope">
          {{ scope.row.is_end_course ? "全部结束" : "没有结束" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="caiwuaudit"
        fixed="right"
        label="财务审核"
        width="90"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small">
            <div>
              <el-link
                v-if="scope.row.is_financial_audit === 0"
                @click.prevent="examine(scope.row.id, 1)"
              >
                通过
              </el-link>
              <span class="pays" v-else>已通过</span>
              <br />

              <a
                href
                v-if="scope.row.is_financial_audit == 0"
                class="danger_link danger_links"
                style="margin-top: 5px; display: block"
                @click.prevent="examine(scope.row.id, 2)"
                >驳回</a
              >
              <span
                style="color: #999999; margin-top: 5px; display: block"
                v-else
                >驳回</span
              >
            </div>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="coursestate"
        fixed="right"
        label="付款状态"
        width="90"
      >
        <template slot-scope="scope">
          <span :class="{ pays: 'ispays' }" v-if="scope.row.is_payment == 1">
            已结算</span
          >
          <span :class="{ pay: 'ispays' }" v-else> 未付款</span>
        </template>
      </el-table-column>
      <el-table-column prop="qrpays" fixed="right" label="确认付款" width="90">
        <template slot-scope="scope">
          <el-button
            @click.prevent="confirmPayMeth(scope.row.id)"
            size="mini"
            :disabled="
              !(
                scope.row.is_financial_audit === 1 && scope.row.is_payment === 0
              )
            "
            >确认</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="pay_card" label="付款账号" width="120">
      </el-table-column>
    </el-table>
    <!-- 导出数据专用表格 -->
    <el-table
      :data="selectList"
      tooltip-effect="dark"
      style="width: 100%"
      id="daochu2"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="course_name" label="课程名称" width="120">
      </el-table-column>
      <el-table-column prop="class_time" label="上课时间" width="110">
      </el-table-column>
      <el-table-column prop="user_name" label="学生姓名" width="120">
      </el-table-column>
      <el-table-column prop="department" label="部门名称" width="120">
      </el-table-column>
      <el-table-column prop="contract_type" label="合同类型" width="120">
      </el-table-column>
      <el-table-column prop="gwname" label="对接老师" width="120">
      </el-table-column>
      <el-table-column prop="course_duration" label="课程时长" width="120">
      </el-table-column>
      <el-table-column prop="teacher_name" label="海师姓名" width="120">
      </el-table-column>
      <el-table-column
        prop="service_category_name"
        label="服务类型"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="service_content" label="服务内容" width="120">
      </el-table-column>
      <el-table-column prop="course_price" label="课程单价" width="120">
        <template slot-scope="scope">
          {{ scope.row.currency == 1 ? "￥" : "$" }}{{ scope.row.course_price }}
        </template>
      </el-table-column>
      <el-table-column prop="confirm_course_price" label="确认单价" width="120">
        <template slot-scope="scope">
          {{ scope.row.currency == 1 ? "￥" : "$"
          }}{{ scope.row.confirm_course_price }}
        </template>
      </el-table-column>
      <!-- 课程状态 -->
      <el-table-column
        prop="coursestate"
        fixed="right"
        label="课程状态"
        width="90"
      >
        <template slot-scope="scope">
          {{ scope.row.is_end_course ? "全部结束" : "没有结束" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="caiwuaudit"
        fixed="right"
        label="财务审核"
        width="90"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small">
            <div>
              <el-link
                v-if="scope.row.is_financial_audit === 0"
                @click.prevent="examine(scope.row.id, 1)"
              >
                通过
              </el-link>
              <span class="pays" v-else>已通过</span>
              <br />

              <a
                href
                v-if="scope.row.is_financial_audit == 0"
                class="danger_link danger_links"
                style="margin-top: 5px; display: block"
                @click.prevent="examine(scope.row.id, 2)"
                >驳回</a
              >
              <span
                style="color: #999999; margin-top: 5px; display: block"
                v-else
                >驳回</span
              >
            </div>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="coursestate"
        fixed="right"
        label="付款状态"
        width="90"
      >
        <template slot-scope="scope">
          <span :class="{ pays: 'ispays' }" v-if="scope.row.is_payment == 1">
            已结算</span
          >
          <span :class="{ pay: 'ispays' }" v-else> 未付款</span>
        </template>
      </el-table-column>
      <el-table-column prop="qrpays" fixed="right" label="确认付款" width="90">
        <template slot-scope="scope">
          <el-button
            @click.prevent="confirmPayMeth(scope.row.id)"
            size="mini"
            :disabled="
              !(
                scope.row.is_financial_audit === 1 && scope.row.is_payment === 0
              )
            "
            >确认</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="pay_card" label="付款账号" width="120">
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <!-- <div @click="exportExcel('#daochu', '表格导出')">导出</div> -->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      @current-change="pageChange"
    >
    </el-pagination>
  </div>
</template>
<script>
import {
  getFinanceList,
  confirmPayment,
  financialAudit,
} from "@/api/financial.js";
// 引入导出Excel表格依赖
import FileSaver from "file-saver";
import XLSX from "xlsx";
export default {
  name: "",
  components: {},
  // props: ["type"],
  props: {
    type: {},
    dateRange: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true, //默认打开加载状态
      tableData: [],
      keywords: "",
      currentPage: 0,
      pageSize: 0,
      total: 0,
      ispays: true,
      selectList: [], // 选中的数据
      //部门选项
      bmOptions: [
        {
          value: "美研",
          label: "美研",
        },
        {
          value: "日本",
          label: "日本",
        },
        {
          value: "英国",
          label: "英国",
        },
        {
          value: "美高",
          label: "美高",
        },
        {
          value: "美本",
          label: "美本",
        },
      ],
      bmValue: "",
      //合同选项
      htOptions: [
        {
          value: "留学",
          label: "留学",
        },
        {
          value: "考试",
          label: "考试",
        },
        {
          value: "背景提升",
          label: "背景提升",
        },
      ],
      htValue: "",
    };
  },
  watch: {},
  created() {},
  beforeMount() {},
  methods: {
    //定义导出Excel表格事件
    exportExcel() {
      //判断是否为空
      if (this.selectList.length === 0) {
        this.$message({
          message: "无数据",
          type: "warning",
        });
        return;
      }
      // 解决生成重复数据-因为使用l fixed属性
      var fix = document.querySelector("#daochu2 .el-table__fixed-right");
      var wb;
      // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
      if (fix) {
        /* 从表生成工作簿对象 */
        wb = XLSX.utils.table_to_book(
          document.querySelector("#daochu2").removeChild(fix),
          { raw: true }
        );
        document.querySelector("#daochu2").appendChild(fix);
      } else {
        wb = XLSX.utils.table_to_book(document.querySelector("#daochu2"), {
          raw: true,
        });
      }
      /* 获取二进制字符串作为输出 */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          // Blob 对象表示一个不可变、原始数据的类文件对象。
          // Blob 表示的不一定是JavaScript原生格式的数据。
          // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
          // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
          new Blob([wbout], { type: "application/octet-stream" }),
          // 设置导出文件名称
          "统计核算.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      this.$refs.multipleTable.clearSelection();
      return wbout;
    },
    //表格选中事件回调
    handleSelectionChange(val) {
      //选中批量导出的 给导出专用表格赋值数据
      this.selectList = val; //选中数据
    },

    //确认付款
    confirmPayMeth(val) {
      let type = this.type;
      let selectList = this.selectList;
      if (val === "batch") {
        if (selectList.length > 0) {
          let selectedId = [];
          selectList.forEach((item) => {
            selectedId.push(item.id);
          });
          this.confirmPayReq({
            courses_order_id: selectedId.join(),
            type: 1,
          });
          return;
        }
        this.$message.warning("请选择数据");
      } else {
        this.confirmPayReq({ courses_order_id: val, type: 1 });
      }
    },
    //确认付款请求
    confirmPayReq(val) {
      confirmPayment(val).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.data);
          this.getFinanceListMeth();
        }
        this.$refs.multipleTable.clearSelection();
      });
    },
    //审核
    examine(id, type) {
      let str = type === 1 ? "通过" : "驳回";
      this.$confirm(`确定${str}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            courses_order_id: id,
            type,
          };
          financialAudit(params).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.data);
              this.getFinanceListMeth();
            }
          });
        })
        .catch(() => {});
    },
    // 获取列表
    getFinanceListMeth(val) {
      this.loading = true; //关闭加载状态
      if (val === "search") {
        this.currentPage = 1;
      }
      let { type, keywords, dateRange, bmValue, htValue } = this;
      if (dateRange == null) {
        dateRange = ["", ""];
      }
      let param = {
        offset: 1,
        limit: 20,
        page: this.currentPage,
        type,
        keywords, //模糊查询-搜索
        start_time: dateRange[0] || "",
        end_time: dateRange[1] || "",
        department: bmValue, //部门
        contract_type: htValue, //合同类型
      };
      getFinanceList(param).then((res) => {
        this.loading = false; //关闭加载状态
        if (res.code === 200) {
          let { count, list, paging } = res.data;
          this.tableData = list;
          this.total = count;
          this.pageSize = paging.limit;
        }
      });
    },
    //重置
    reset() {
      this.bmValue = "";
      this.htValue = "";
      this.keywords = "";
      this.getFinanceListMeth();
    },
    // 页码切换
    pageChange(page) {
      this.currentPage = page;
      this.getFinanceListMeth();
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
// 隐藏导出表格
#daochu2 {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
//搜索框
.sousuo {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  .reset {
    color: #f5f5f5;
    cursor: pointer;
    margin-left: 20px;
    padding: 0 30px;
    border-radius: 4px;
    line-height: 40px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    position: relative;
    &:active {
      position: relative;
      bottom: -1px;
    }
  }
  /deep/ .el-select {
    width: 120px;
    margin-left: 10px;
  }
  .inputSearch {
    display: block;
    width: 520px;
  }
  /deep/.el-input__inner {
    background: #fafafa;
    &.el-input__inner:focus {
      border-color: #d99d66;
    }
  }
  /deep/.el-input-group__append {
    background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
    color: #935d14;
    cursor: pointer;
  }
  .el-input.el-input-group .el-input-group__append,
  .el-input-group__prepend {
    border: none !important;
  }
}

.danger_links {
  color: #333333;
  text-decoration: none;
}

.danger_link:hover {
  color: #f4523b;
  border-bottom: 1px solid #f4523b;
}

.danger_link.el-link.el-link--default:hover:after {
  content: "";
  // display:block;
  // width:100%;
  // height:1px;
  // position: absolute;
  // bottom:0;
  // left:0;
  background: #fff;
  color: #fff;
  //   // color:#f4523b!important;
  //   background:#f4523b!important;
}

// 字体颜色
.pays {
  color: #68c23a;
}
.pay {
  color: #e6a23b;
}
</style>
