<template>
  <div class="account_settings">
    <!-- 居中 -->
    <div class="content">
      <!-- 面包屑导航 start -->
      <bread-crumb :nav_title="nav_title" />
      <!-- 面包屑导航 end -->

      <div class="content_box">
        <!-- 侧边导航 start -->
        <nav-left :xuanzhong="5" />
        <!-- 侧边导航 end -->
        <!-- 右边内容 -->
        <div class="content_total">
          <div class="nav">
            <!-- 测试结束 -->
            <div class="nav_header">
              <el-tabs v-model="activeName" @tab-click="tabClick">
                <el-tab-pane label="全部订单" name="firstTable">
                  <Table
                    :type="0"
                    :dateRange="dateRange"
                    ref="firstTable"
                  ></Table>
                </el-tab-pane>
                <el-tab-pane label="已结算" name="secondTable">
                  <Table
                    :type="1"
                    ref="secondTable"
                    :dateRange="dateRange"
                  ></Table>
                </el-tab-pane>
                <el-tab-pane label="未结算" name="thirdTable">
                  <Table
                    :type="2"
                    ref="thirdTable"
                    :dateRange="dateRange"
                  ></Table>
                </el-tab-pane>
                <el-tab-pane label="已审核" name="fourthTable">
                  <Table
                    :type="3"
                    ref="fourthTable"
                    :dateRange="dateRange"
                  ></Table>
                </el-tab-pane>
              </el-tabs>
              <!-- 时间选择器 start-->
              <div class="rights">
                <div class="block">
                  <el-date-picker
                    v-model="dateRange"
                    type="datetimerange"
                    start-placeholder="开始日期"
                    value-format="yyyy-MM-dd"
                    end-placeholder="结束日期"
                    :default-time="['12:00:00']"
                    @change="dateChange()"
                  >
                  </el-date-picker>
                </div>
                <!-- 时间选择器 end-->
                <!-- 批量导出 -->
                <el-button  @click="exportData()"
                  >批量导出</el-button
                >
                <!-- 批量确认付款 -->
                <el-button @click="confirmPay('batch')">批量确认付款</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "../components/breadcrumb";
import NavLeft from "../components/NavLeft";
import { getFinanceList } from "@/api/financial.js";
import Table from "./components/table";
import * as echarts from "echarts";

export default {
  data() {
    return {
      swiperOptionsOne: {
        slidesPerView:3,
        loop: true,
        // loopedSlides: 6,
        // direction: "vertical",
        observeParents: true,
        observer: true,
        direction: "vertical",

      },
      swiperOptionsTwo: {
        direction: "vertical",
        nested: true,
        //resistanceRatio: 0,
        slidesPerView: "auto",
        freeMode: true,
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      },
      nav_title: [{ name: "财务中心" }, { name: "统计核算" }],
      dateRange: [],
      activeName: "firstTable",
      tableData: [], //全部
      tableEnd: [], //1 已结束
      tableUnsettled: [], //2 未结算
      tableChecked: [], //3 已审核
      multipleSelection: [],
    };
  },
  components: {
    breadCrumb: breadCrumb,
    NavLeft,
    Table,
  },
  methods: {
    // 确认付款
    confirmPay() {
      this.$refs[this.activeName].confirmPayMeth("batch");
    },
    // 导出
    exportData() {
      this.$refs[this.activeName].exportExcel("batch");
    },
    // 日期筛选
    dateChange(val) {
      this.$nextTick(() => {
        this.$refs[this.activeName].getFinanceListMeth();
      });
    },
    // tab切换
    tabClick(a) {
      this.tableInit(this.activeName);
      this.dateRange = [];
    },
    // 获取表格数据
    tableInit(type) {
      this.$refs[type].keywords = "";
      this.$refs[type].getFinanceListMeth();
    },
  },
  mounted() {
    this.tableInit(this.activeName);
  },
};
</script>

<style lang="scss" scoped>
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden;
}

.account_settings {
  background: #f5f5f5;
  padding-bottom: 30px;

  .content {
    width: 1200px;
    margin: 0 auto;
    .content_box {
      display: flex;
    }
    .content_total {
      display: flex;
      .nav {
        width: 990px;
        background: #ffffff;
        border-radius: 8px;
        margin-left: 10px;
        // 表头
        .nav_header {
          border-radius: 8px 8px 0px 0px;
          position: relative;
          .block {
            // position: absolute;
            // left: 384px;
            margin-right: 10px;
          }
          //表头样式
          /deep/ .el-tabs {
            .el-tabs__item {
              line-height: 0px;
              height: 35px;
              font-size: 18px;
              color: #666666;
            }
            .el-tabs__active-bar {
              width: 70px;
              height: 4px;
              background: #d99d66;
              border-radius: 2px;
            }
            .el-tabs__item.is-active {
              color: #d99d66;
              font-weight: bold;
            }
            .el-tabs__item:hover {
              color: #d99d66;
            }
            .el-tabs__nav-wrap::after {
              background-color: #fff !important;
            }
            .el-tabs__nav-scroll {
              background: #fcfcfc;
              padding: 25px 0 0 20px;
            }
          }
          // 日期表start
          .el-date-editor--datetimerange.el-input,
          .el-date-editor--datetimerange.el-input__inner {
            width: 230px;
          }
          /deep/ .el-input__inner {
            // height: 30px;
            line-height: 30px;
          }
          /deep/.el-date-editor .el-range-separator {
            line-height: 24px;
          }
          /deep/.el-date-editor .el-range__icon {
            line-height: 24px;
          }
          // 日期表end

          // 表格标题
          /deep/ .el-table thead {
            font-size: 13px;
            font-weight: 500;
            color: #666666;
          }
          /deep/ .el-table--border {
            margin: 20px;
            .el-table td,
            .el-table th.is-leaf {
              font-size: 13px;
              font-weight: 500;
              color: #666666;
              line-height: 20px;
            }
          }
          /deep/ .el-table td,
          .el-table th {
            text-align: center;
          }
          // 表头边框去除
          /deep/ .el-table th,
          .el-table tr {
            background: #f5f5f5;
            border-radius: 4px 4px 0px 0px;
            text-align: center;
            border: none;
          }

          // 表格
          /deep/ .el-table {
            max-width: 98%;
            margin-left: 10px;
            margin-top: 20px;
            .el-divider__text,
            .el-link {
              font-weight: 500;
              font-size: 12px;
            }
            .el-tag {
              width: 42px;
              height: 22px;
              line-height: 22px;
              border-radius: 2px;
              border: 1px solid rgba(24, 144, 255, 0.3);
              background: #fff;
            }
            .el-link--inner {
              margin-top: 5px;
            }
            .cancel {
              margin-top: 5px;
            }
            // 鼠标移入字体颜色改变
            // .el-link.el-link--default:hover {
            //    color: #f4523b;
            // }
          }
          // 分页器start
          /deep/ .el-pagination {
            color: #999999;
            text-align: center;
            margin-top: 40px;
            .el-pager li:not(.disabled).active {
              width: 44px;
              height: 30px;
              background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
              border-radius: 4px 8px 4px 8px;
              font-size: 16px;
              color: #ffffff;
            }
            .btn-prev,
            .el-pager li {
              width: 44px;
              height: 30px;
              border-radius: 4px 8px 4px 8px;
              border: 1px solid #ededed;
              font-size: 16px;
              font-weight: 400;
              color: #999999;
            }
            .btn-next {
              width: 44px;
              height: 30px;
              border-radius: 4px 8px 4px 8px;
              border: 1px solid #ededed;
              color: #cfcfcf;
            }
          }
          // 分页器end
        }
        /deep/.el-button--text {
          color: #666666;
        }
        .rights {
          position: absolute;
          top: 0;
          height: 68px;
          padding-left: 422px;
          display: flex;
          align-items: center;
          // /deep/ .el-button + .el-button {
          // }
          /deep/.el-button {
            padding: 6px 10px;
          }
        }
      }
    }
  }
}

.swiperTwo.swiper-slide {
  height: 800px !important;
  background: yellow;
}

// 测试开始
/deep/ .swiper-slide{
  width:50px;
  height:100px!important;
  // height:200px!important;
}
.test {
  width: 200px;
  height: 200px;
  background: #95d7db;
  .con{
    height: 200px;
    .swiper-container{
      height:100%;
    }
  }
}

</style>
